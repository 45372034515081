<template>
  <v-row align="center" justify="center" class="pa-4">
    <v-col>
      <h1 style="font-weight: 800; color: #FF0000">ข้อมูลบริษัท (EPP)</h1>
    </v-col>
    <v-card class="pa-8" width="100%">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-row align="center" justify="center">
          <v-img :src="showImage" max-width="300px"></v-img>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">รหัสบริษัท</span>
            <span style="color: red">*</span>
          </v-col>
          <v-col cols="6" md="7" sm="7">
            <span>{{ companyCode }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">ชื่อบริษัท</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="6" md="7" sm="7">
            <span>{{ companyName }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">อีเมลบริษัท</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="6" md="7" sm="7">
            <span>{{ suffixEmail }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">รายละเอียด</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="6" md="7" sm="7">
            <span>{{ description }}</span>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">โลโก้บริษัท</span>
          </v-col>
          <v-col cols="6" md="7" sm="7">
            <v-img :src="showImage" width="200px"></v-img>
          </v-col>
        </v-row> -->
        <v-tabs class="mt-4">
          <v-tab v-for="item in tabitems" :key="item.key" @click="tab(item)">{{
            item.text
          }}</v-tab>
        </v-tabs>
        <v-col cols="12" md="12">
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                class="mb-4"
                v-model="search"
                dense
                hide-details
                outlined
                placeholder="ค้นหา"
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <!-- <v-col class="text-right">
            <v-btn @click="goToCreate()">สร้าง Reference Code</v-btn>
          </v-col> -->
            <v-col cols="12" md="6" class="text-right">
              <input
                type="file"
                accept=".csv"
                id="file"
                ref="file"
                v-on:change="handleFileUpload()"
              />
              <v-btn
                color="red"
                style="color: white"
                v-on:click="submitFile()"
                >upload</v-btn
              >
            </v-col>
            <!-- <v-col cols="12" md="3" class="text-right"></v-col> -->
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-card
            ><v-data-table
              :headers="headersCategory"
              :items="items"
              :search="search"
              :items-per-page="10"
              class="elevation-1"
            >
              <template v-slot:[`item.name`]="{ item }">
                <span>{{ item.firstname }} {{ item.lastname }}</span>
              </template>
              <template v-slot:[`item.activeFlag`]="{ item }">
                <span v-if="item.activeFlag == true">ใช้งาน</span>
                <span v-if="item.activeFlag == false">ไม่ใช้งาน</span>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-row justify="center">
                  <v-icon @click="viewUser(item)">mdi-eye</v-icon>
                  <!-- <v-icon @click="UpdateCate(item)" class="mx-2"
                  >mdi-pencil</v-icon
                > -->
                  <v-icon @click="DeleteUser(item)">mdi-delete</v-icon>

                  <!-- <v-col cols="4" class="mx-0 px-1"
              ><v-btn @click="GetQuestions(item)" color="#833133" dark
                ><v-icon>mdi-magnify</v-icon></v-btn
              ></v-col
            > -->
                </v-row>
              </template>
            </v-data-table></v-card
          >
        </v-col>
        <v-row align="center" justify="end" class="mt-10">
          <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
          <!-- <v-btn @click="submit()" color="primary">บันทึก</v-btn> -->
        </v-row>
      </v-form>
    </v-card>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      file: "",
      type: "",
      headersCategory: [],
      headersUser: [
        { text: "ลำดับ", value: "count", align: "center" },
        // { text: "username", value: "username", align: "center" },
        { text: "ชื่อ", value: "name", align: "center" },
        { text: "หมายเลขโทรศัพท์", value: "tel", align: "center" },
        { text: "email", value: "email", align: "center" },
        { text: "ประเภท", value: "type", align: "center" },
        // { text: "verify", value: "isVerify", align: "center" },
        { text: "ตัวเลือก", value: "action", align: "center" },
      ],
      headersUSER_DATA: [
        // activeFlag: true
        // companyId: 14
        // count: 1
        // createdAt: "2021-08-24T12:56:20.000Z"
        // empCode: "999999"
        // empName: "ITTIWUT WONGSAWAT"
        // empPosition: "ADMIN"
        // id: 2839
        { text: "ลำดับ", value: "count", align: "center" },
        { text: "รหัสพนักงาน", value: "empCode", align: "center" },
        { text: "ชื่อ-สกุล", value: "empName", align: "center" },
        { text: "ตำแหน่ง", value: "empPosition", align: "center" },
        { text: "ใช้งาน", value: "activeFlag", align: "center" },
        { text: "ตัวเลือก", value: "action", align: "center" },
      ],
      search: "",
      count: 0,
      items: [],
      // รหัสบริษัท
      // ชื่อบริษัท
      // Email บริษัท
      // รายละเอียด
      // รูปภาพโลโก้
      // // แบรนด์สินค้า
      companyCode: "",
      companyName: "",
      suffixEmail: "",
      description: "",
      companyImg: "",
      brand: [],
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      activeFlag: true,
      tabitems: [
        { text: "พนักงานที่สมัครเข้ามาแล้ว", key: "USER" },
        { text: "ข้อมูลพนักงานทั้งหมด", key: "USER_DATA" },
      ],

      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        phone: [
          (v) =>
            (v.length <= 10 && v.length >= 9) ||
            "หมายเลขโทรศัพท์ต้องมี 9-10 ตัวเท่านั้น",
          (v) => !!v || "กรุณากรอกข้อมูลให้ครบถ้วน",
        ],
        maxAge: [
          (v) => v.length <= 2 || "ระบุอายุระหว่าง 12-60ปี เท่านั้น",
          (v) => !!v || "This field is required",
        ],
      },
      checkValidate: true,
      lazy: false,
      user: "",
    };
  },
  created() {
    var Companydata = JSON.parse(
      Decode.decode(localStorage.getItem("Companydata"))
    );
    console.log("Companydata", Companydata);
    this.id = Companydata.id;
    this.companyCode = Companydata.companyCode;
    this.companyName = Companydata.companyName;
    this.suffixEmail = Companydata.suffixEmail;
    this.description = Companydata.description;
    this.showImage = Companydata.companyImg;
    this.pic1 = Companydata.companyImg;
    this.picture_1 = Companydata.companyImg;
    this.activeFlag = Companydata.activeFlag;
    // this.getAllUsers();
    this.tabitems.key == "USER";
    this.getAllUsers();
    this.headersCategory = this.headersUser;
  },
  methods: {
    async tab(val) {
      console.log("log", val.key);
      // /orders?status=รอการยืนยัน
      if (val.key == "USER") {
        this.getAllUsers();
        this.headersCategory = this.headersUser;
      } else if (val.key == "USER_DATA") {
        this.getAllUsersData();
        this.headersCategory = this.headersUSER_DATA;
      }
    },
    async getAllUsers() {
      var user = JSON.parse(
        Decode.decode(localStorage.getItem("userHealthAdmin"))
      );
      const auth = {
        headers: { Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/users?companyId=` + this.id,
        auth
        // 'http://localhost:8081/categories/'
      );
      console.log("users1", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
      }
    },
    async getAllUsersData() {
      var user = JSON.parse(
        Decode.decode(localStorage.getItem("userHealthAdmin"))
      );
      const auth = {
        headers: { Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/users/getEPPUsers?companyId=` + this.id,
        auth
        // 'http://localhost:8081/categories/'
      );
      console.log("users2", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
      }
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("ManageCompany");
    },
    // async submit() {
    //   if (this.$refs.createForm.validate(true)) {
    //     var user = JSON.parse(
    //       Decode.decode(localStorage.getItem("userHealthAdmin"))
    //     );
    //     const auth = {
    //       headers: { Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E` },
    //     };
    //     const data = {
    //       id: this.id,
    //       companyCode: this.companyCode,
    //       companyName: this.companyName,
    //       suffixEmail: this.suffixEmail,
    //       description: this.description,
    //       companyImg: this.pic1,
    //       activeFlag: this.activeFlag,
    //     };
    //     const response = await this.axios.put(
    //       `${process.env.VUE_APP_API}/companies/` + this.id,
    //       data,
    //       auth
    //     );
    //     console.log("createCompany", response);
    //     console.log(response.data.response_status);
    //     if (response.data.response_status == "SUCCESS") {
    //       this.$swal.fire({
    //         icon: "success",
    //         text: `แก้ไขข้อมูลบริษัทสำเร็จ`,
    //         showConfirmButton: false,
    //         timer: 1500,
    //       });
    //       this.$router.push("ManageCompany");
    //     } else {
    //       this.$swal.fire({
    //         icon: "error",
    //         text: `แก้ไขข้อมูลบริษัทไม่สำเร็จ`,
    //         showConfirmButton: false,
    //         timer: 1500,
    //       });
    //     }
    //   }
    // },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      console.log("file", file);
      console.log(this.type);
    },

    async submitFile() {
      if (this.$refs.formupload.validate(true)) {
        let formData = new FormData();
        formData.append("file", this.file);
        formData.append("type", this.type);
        formData.append("companyId", this.id);
        console.log("formData", formData);
        const auth = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer` + " " + this.checkuser.token,
          },
        };
        console.log("auth", auth);
        this.uploaded = true;
        const response = await axios.post(
          `${process.env.VUE_APP_API}/xxxx/formData`,
          formData,
          auth
        );
        // .then(function () {
        console.log("SUCCESS!!");
        console.log("response", response.data);
        this.uploaded = false;
        if (response.data.response_status == "SUCCESS") {
          await this.$swal
            .fire({
              text: "อัพโหลดข้อมูลสำเร็จแล้ว กดเพื่อดูข้อมูล",
              icon: "success",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "ดูข้อมูล",
              cancelButtonText: "ยกเลิก",
              reverseButtons: true,
            })
            .then((result) => {
              if (result.isConfirmed) {
                this.$router.push(this.type);
              }
            });
        } else {
          await this.$swal.fire({
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
            icon: "error",
            text: "อัพโหลดไฟล์ไม่สำเร็จ",
          });
        }
        // })
        // .catch(function () {
        //   console.log("FAILURE!!");
        // });
      }
    },
  },
};
</script>